import "./App.css";
import { useMediaQuery } from "@react-hook/media-query";
import Nav from "./components/nav/Nav";
import About from "./components/about/About";
import Experience from "./components/experience/Experience";
import Portfolio from "./components/portfolio/Portfolio";
import Testimonials from "./components/testimonials/Testimonials";
import Contact from "./components/contact/Contact";
import Footer from "./components/footer/Footer";
import Carousel from "./components/carousel/Carousel";
import Canvas from "./components/canvas/CanvasAnimation";
function App() {
  const isSmallScreen = useMediaQuery("(max-width: 768px)");
  return (
    <div className="App">
      {!isSmallScreen && <Canvas />}

      <Nav />
      <About />
      <Carousel />
      <Experience />
      <Portfolio />
      <Testimonials />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
