import React from "react";
import CTA from "./CTA";
import ME from "../../assets/me.png";
import "./Header.css";
import HeaderSocials from "./HeaderSocials";
export default function Header() {
  return (
    <header>
      <div className="container header__container">
        <h3>Hello I'm</h3>
        <h1>Ali Asim</h1>
        <h4>Fullstack Development</h4>
        <HeaderSocials />
      </div>
    </header>
  );
}
