import React from "react";
import "./Portfolio.css";
import IMG1 from "../../assets/Loop.png";
import IMG2 from "../../assets/F4S.png";
import IMG3 from "../../assets/Rossetti.jpg";
import IMG4 from "../../assets/KW-Legacy.jpg";
import IMG5 from "../../assets/thiot.png";
import IMG6 from "../../assets/bioptimizers.png";
export default function Portfolio() {
  return (
    <section id="portfolio">
      <h4>My Recent Work</h4>
      <h2>Portfolio</h2>
      <div className="container portfolio__container">
        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={IMG1} alt="" />
          </div>
          <div className="portfolio__item-cta">
            <span>LoopHQ</span>
            <a
              href="https://loophq.com/"
              target="_blank"
              className="btn btn-primary"
            >
              Live
            </a>
          </div>
        </article>
        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={IMG2} alt="" />
          </div>
          <div className="portfolio__item-cta">
            <span>F45Training</span>
            <a
              href="https://f45training.com/"
              target="_blank"
              className="btn btn-primary"
            >
              Live
            </a>
          </div>
        </article>
        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={IMG3} alt="" />
          </div>
          <div className="portfolio__item-cta">
            <span>Rosetti</span>
            <a
              href="https://www.rossetti.com/"
              target="_blank"
              className="btn btn-primary"
            >
              Live
            </a>
          </div>
        </article>
        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={IMG4} alt="" />
          </div>
          <div className="portfolio__item-cta">
            <span>KwLegacy</span>
            <a
              href="https://kwlegacydearborn.com/"
              target="_blank"
              className="btn btn-primary"
            >
              Live
            </a>
          </div>
        </article>
        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={IMG5} alt="" />
          </div>
          <div className="portfolio__item-cta">
            <span>Thiot ingenierie</span>
            <a
              href="https://www.thiot-ingenierie.com/en/"
              target="_blank"
              className="btn btn-primary"
            >
              Live
            </a>
          </div>
        </article>
        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={IMG6} alt="" />
          </div>
          <div className="portfolio__item-cta">
            <span>BiOptimzers</span>
            <a
              href="https://bioptimizers.com/"
              target="_blank"
              className="btn btn-primary"
            >
              Live
            </a>
          </div>
        </article>
      </div>
    </section>
  );
}
