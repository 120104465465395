import React from "react";
import "./Experience.css";
import { BsPatchCheckFill } from "react-icons/bs";
export default function Experience() {
  return (
    <section id="experience">
      <h4>What Skills I Have</h4>
      <h2>My Experience</h2>
      <div className="container experience__container">
        <div className="experience__frontend">
          <h3>Frontend Development</h3>
          <div className="experience__content">
            <article className="experience__backend">
              <BsPatchCheckFill />
              <div>
                <h4>HTML</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>
            <article className="experience__backend">
              <BsPatchCheckFill />
              <div>
                <h4>CSS/SASS/JSS</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>
            <article className="experience__backend">
              <BsPatchCheckFill />
              <div>
                <h4>JavaScript</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>
            <article className="experience__backend">
              <BsPatchCheckFill />
              <div>
                <h4>Bootstrap/Material UI</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>
            <article className="experience__backend">
              <BsPatchCheckFill />
              <div>
                <h4>React/Angular</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>
            <article className="experience__backend">
              <BsPatchCheckFill />
              <div>
                <h4>3JS/GSAP</h4>
                <small className="text-light">Adequate knowledge</small>
              </div>
            </article>
            <article className="experience__backend">
              <BsPatchCheckFill />
              <div>
                <h4>Wordpress/WooCommerce</h4>
                <small className="text-light">All a blur</small>
              </div>
            </article>
          </div>
        </div>
        <div className="experience__backend">
          <h3>Backend Development</h3>
          <div className="experience__content">
            <article className="experience__backend">
              <BsPatchCheckFill />
              <div>
                <h4>Node</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>
            <article className="experience__backend">
              <BsPatchCheckFill />
              <div>
                <h4>MongoDB</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>
            <article className="experience__backend">
              <BsPatchCheckFill />
              <div>
                <h4>MySQL/SQL/SQLlite</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>
            <article className="experience__backend">
              <BsPatchCheckFill />
              <div>
                <h4>Rest API/Graph QL</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>
            <article className="experience__backend">
              <BsPatchCheckFill />
              <div>
                <h4>Symfony / PHP</h4>
                <small className="text-light">Adequate knowledge</small>
              </div>
            </article>
            <article className="experience__backend">
              <BsPatchCheckFill />
              <div>
                <h4>Firebase</h4>
                <small className="text-light">Adequate knowledge</small>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
}
