import AVTR1 from "../../assets/face1.jpeg";
import AVTR2 from "../../assets/face2.jpeg";
import AVTR3 from "../../assets/face 3.jpeg";

const data = [
  {
    avatar: AVTR1,
    name: "Tina Snow",
    review:
      "I have had the pleasure of working with Ali multiple times. His attention to detail and creative approach to design truly sets him apart. Working with Ali was a seamless experience, and he consistently delivered high-quality results.",
  },
  {
    avatar: AVTR2,
    name: "Adrien Rostovsky",
    review:
      "Ali has a rare combination of technical expertise and design sensibility that results in visually stunning and highly functional websites. Ali's dedication to delivering a seamless user experience is evident in every project he undertakes.",
  },
  {
    avatar: AVTR3,
    name: "Matt Galant",
    review:
      "I've had the pleasure of working with Ali on multiple projects, and I'm consistently impressed by his proficiency in front-end development. He brings a level of creativity to his work that truly sets him apart.",
  },
];
export default data;
