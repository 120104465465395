import React from "react";
import "./About.css";
import ME from "../../assets/me-about.jpg";
import { FaAward } from "react-icons/fa";
import { FiUsers } from "react-icons/fi";
import { VscFolderLibrary } from "react-icons/vsc";
import Header from "../header/Header";
import CV from "../../assets/cv.pdf";
export default function About() {
  return (
    <section id="about">
      <div className="container about__container">
        <div className="about__me">
          <Header />
        </div>
        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className="about__icon" />
              <h5>Work</h5>
              <small> 4+ Years Working</small>
            </article>
            <article className="about__card">
              <FiUsers className="about__icon" />
              <h5>Clients</h5>
              <small>10+ Clients WorldWide</small>
            </article>
            <article className="about__card">
              <VscFolderLibrary className="about__icon" />
              <h5>Experience</h5>
              <small>10+ Completed projects</small>
            </article>
          </div>
          <p>
            I am a REACT / FULL STACK WEB ENGINEER with well over 5 years of
            professional experience in developing web applications and beautiful
            websites from scratch. I have a Bachelor of Computer Science from
            the FAST Lahore. My journey as a web developer has been impressive
            and I continue to grow and learn every day. I am a great fit for any
            startup or web agency, with my range of skills I have the ability to
            take on any size project or client and deliver a product or website
            that meets today’s web standards and expectations.
          </p>
          <a href="#contact" className="btn btn-primary">
            Lets Talk
          </a>
          <a href={CV} download className="btn">
            Download CV
          </a>
        </div>
      </div>
    </section>
  );
}
