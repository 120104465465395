import React from "react";
import { BsLinkedin } from "react-icons/bs";
import { SiGmail } from "react-icons/si";
import { BsGithub } from "react-icons/bs";

export default function HeaderSocials() {
  return (
    <div className="header__socials">
      <a href="https://github.com/aliasim55" target="_blank">
        <BsGithub />
      </a>
      <a href="https://www.linkedin.com/in/ali-asim-1008a7295/" target="_blank">
        <BsLinkedin />
      </a>
      <a href="mailto:aliasim.core.dev@gmail.com" target="_blank">
        <SiGmail />
      </a>
    </div>
  );
}
