import React from "react";
import "./Contact.css";
import { MdOutlineEmail } from "react-icons/md";
// import { RiMessengerLine } from "react-icons/ri";
import { BsWhatsapp } from "react-icons/bs";
export default function contact() {
  return (
    <section id="contact">
      <h4>Get In Touch</h4>
      <h2>Contact Me</h2>
      <div className="container contact__container">
        <div className="contact__options">
          <a
            href="mailto:aliasim.core.dev@gmail.com"
            target="_blank"
            className="contact__option"
          >
            <MdOutlineEmail className="contact__option-icon" />
            <h4>Email</h4>
            <h5>aliasim.core.dev@gmail.com</h5>
            <a>Send a message</a>
          </a>
          <a
            href="https://wa.me/+971557259568"
            target="_blank"
            className="contact__option"
          >
            <BsWhatsapp className="contact__option-icon" />
            <h4>WhatsApp</h4>
            <h5>+971557259568</h5>
            <a>Send a message</a>
          </a>
        </div>
        {/* <form action="">
          <input
            type="text"
            name="name"
            placeholder="Your Full Name"
            required
          />
          <input type="email" name="email" placeholder="Your Email" required />
          <textarea name="message" rows="7" placeholder="Your Message" required>
            {" "}
          </textarea>
          <button type="submit" className="btn btn-primary">
            Send Message
          </button>
        </form> */}
      </div>
    </section>
  );
}
